import { TextField } from "@mui/material";
import { useController, useForm } from "react-hook-form";
import { styled } from "styled-components";
import logoImg from "../../assets/img/cleaneco_logo-color.png";
import { SignUp } from "../../api/user/SignUp";

const PageWrap = styled.div`
  width: 500px;
  margin: 0 auto;
`;
const Logo = styled.div`
  width: 100%;
  text-align: center;
  margin-top: 40px;
  img {
    width: 100px;
  }
`;
const LoginSection = styled.div`
  width: 260px;
  margin: 80px auto 0;
  h4 {
    text-align: center;
    padding-bottom: 40px;
    font-weight: 400;
  }
`;

const Form = styled.form`
  width: 100%;
  height: 180px;
  /* display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center; */

  div {
    width: 100%;

    & + div {
      margin-top: 20px;
    }
  }

  button {
    margin-top: 30px;
    width: 100%;
    height: 50px;
    background-color: #5bb331;
    border: none;
    color: #fff;
    cursor: pointer;
  }
`;

export function AdminJoin() {
  const { handleSubmit, control, getValues } = useForm({
    defaultValues: {
      admin_id: "",
      admin_pw: "",
      admin_pw_check: "",
      user_number: "",
      phone_number: "",
      email: "",
    },
  });
  const {
    field: admin_id,
    fieldState: { invalid: admin_id_Field },
  } = useController({
    name: "admin_id",
    control,
    defaultValue: "",
  });

  const {
    field: admin_pw,
    fieldState: { invalid: admin_pw_Field, error: admin_pw_Error },
  } = useController({
    name: "admin_pw",
    control,
    defaultValue: "",
    rules: {
      pattern: {
        value: /^[a-zA-Z0-9]{10}$/,
        message: "영어와 숫자로 이루어진 8글자로 작성해주세요",
      },
    },
  });

  const {
    field: admin_pw_check,
    fieldState: { invalid: admin_pw_check_Field, error: admin_pw_check_Error },
  } = useController({
    name: "admin_pw_check",
    control,
    defaultValue: "",
    rules: {
      validate: (value) =>
        value === getValues("admin_pw") || "비밀번호가 일치하지 않습니다",
    },
  });

  const {
    field: user_number,
    fieldState: { invalid: user_number_Field },
  } = useController({
    name: "user_number",
    control,
    defaultValue: "",
  });

  const {
    field: phone_number,
    fieldState: { invalid: phone_number_Field },
  } = useController({
    name: "phone_number",
    control,
    defaultValue: "",
  });

  const {
    field: email,
    fieldState: { invalid: email_Field },
  } = useController({
    name: "email",
    control,
    defaultValue: "",
  });

  const onSubmit = (DATA: any) => {
    postSignUp({ user_name: DATA.admin_id, user_pw: DATA.admin_pw });
  };

  const postSignUp = (DATA: any) => {
    SignUp(DATA)
      .then((response) => {
        if (response.data.status_info.status_code === 201) {
          alert("성공적으로 가입되었습니다");
        } else {
          console.log("실패");
        }
      })
      .catch((err) => {
        console.log("error : ", err);
      });
  };

  return (
    <PageWrap>
      <Logo>
        <img src={logoImg} />
      </Logo>
      <LoginSection>
        <h4>관리자 회원가입</h4>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <TextField
            id="filled-basic"
            size="small"
            // fullWidth
            label="아이디"
            variant="filled"
            {...admin_id}
            error={admin_id_Field}
            required
          />
          <TextField
            id="filled-basic"
            size="small"
            // fullWidth
            label="비밀번호"
            variant="filled"
            {...admin_pw}
            error={admin_pw_Field}
            required
            helperText={admin_pw_Error ? admin_pw_Error.message : ""}
          />
          <TextField
            id="filled-basic"
            size="small"
            // fullWidth
            label="비밀번호 확인"
            variant="filled"
            {...admin_pw_check}
            error={admin_pw_check_Field}
            required
            helperText={
              admin_pw_check_Error ? admin_pw_check_Error.message : ""
            }
          />
          <TextField
            id="filled-basic"
            size="small"
            // fullWidth
            label="주민번호 앞 6자리"
            variant="filled"
            {...user_number}
            error={user_number_Field}
            required
          />
          <TextField
            id="filled-basic"
            size="small"
            // fullWidth
            label="전화번호"
            variant="filled"
            {...phone_number}
            error={phone_number_Field}
            required
          />
          <TextField
            id="filled-basic"
            size="small"
            // fullWidth
            label="이메일"
            variant="filled"
            {...email}
            error={email_Field}
            required
          />
          <button>회원가입</button>
        </Form>
      </LoginSection>
    </PageWrap>
  );
}
