import { styled } from "styled-components";
import { Header } from "../../components/common/Header";
import { GetProductThumbnailImg } from "../../api/client/GetProductThumbnailImg";
import queryString from "query-string";
import Cookies from "universal-cookie";
import { useEffect, useState } from "react";
import { GetSingleProduct } from "../../api/product/single/GetSingleProduct";
import { GetSingleProductImgPath } from "../../api/product/single/GetSingleProductImgPath";
import { GetSingleProductImgList } from "../../api/product/single/GetSingleProductImgList";
import { GetSetProductThumbnail } from "../../api/product/set/GetSetProductThumbnail";
import { GetSetProductBaseInfo } from "../../api/product/set/GetSetProductBaseInfo";
import { GetSetProductDetailImageInfo } from "../../api/product/set/GetSetProductDetailImageInfo";
import { GetSetProductImgList } from "../../api/product/set/GetSetProductImgList";
import { GetProductSetThumbnailImg } from "../../api/client/GetProductSetThumbnailImg";
import { GetSetProducts } from "../../api/client/GetSetProducts";
import { GetAllCategories } from "../../api/client/GetAllCategories";
import { Link } from "react-router-dom";
import { GetAllForLandingPage } from "../../api/client/GetAllForLandingPage";

const PageWrap = styled.div``;

const ContentWrap = styled.div`
  width: 60%;
  margin: 0 auto;

  @media (max-width: 600px) {
    width: auto;
    padding: 40px;
  }
`;
const Title = styled.div`
  font-size: 14px;
  padding-bottom: 30px;

  p {
    a {
      text-decoration: underline;
      color: #787878;
    }
  }
`;
const ThumbNail = styled.div`
  display: flex;
  justify-content: flex-start;

  @media (max-width: 600px) {
    display: block;
  }
`;
const ThumbNailImg = styled.div`
  width: 500px;
  height: 500px;
  /* background-color: #cbcbcb; */

  img {
    width: 100%;
  }

  @media (max-width: 600px) {
    width: 100%;
    height: auto;
    padding-bottom: 40px;
  }
`;

const ThumbNailDetail = styled.div`
  padding-left: 100px;
  width: 400px;

  @media (max-width: 1024px) {
    width: 100%;
  }

  h3 {
    line-height: 56px;
    border-bottom: 3px solid #5bb331;
    font-size: 16px;
  }

  & > div {
    display: flex;
    padding: 30px 0;
    border-bottom: 1px solid #cbcbcb;
    /* & + div {
      padding-top: 50px;
    } */

    p {
      font-size: 16px;
      white-space: nowrap;

      &:first-child {
        width: 100px;
      }

      & + p {
        padding-left: 30px;
        color: #777;
        font-weight: 100;
      }

      div {
        & + div {
          padding-top: 10px;
          /* margin-top: 10px;
          border-top: 5px solid #ececec; */
        }

        &.prd-list {
          white-space: normal;
          line-height: 26px;
        }
      }
    }
  }

  @media (max-width: 600px) {
    padding: 0;
  }
`;

const ContentDetail = styled.div`
  padding-top: 50px;

  h4 {
    padding-bottom: 20px;
  }
  div {
    /* background-color: #cbcbcb; */
    height: 1000px;
    img {
      width: 100%;
    }
  }
`;

export function ProductDetail() {
  const cookies = new Cookies();
  const current_cookies = cookies.get("user_info");
  const queryParams = queryString.parse(window.location.search);

  const prdId =
    typeof queryParams.prd_id === "string" ? queryParams.prd_id : "";

  console.log("prdId : ", prdId);

  const prdName =
    typeof queryParams.prd_name === "string" ? queryParams.prd_name : "";
  const prdType = typeof queryParams.type === "string" ? queryParams.type : "";
  const categoryId =
    typeof queryParams.categoryId === "string" ? queryParams.categoryId : "";

  const [thumbnail, setThumbnail] = useState<string>("");
  const [productInfo, setProductInfo] = useState<any>({});
  const [detailImgPath, setDetailImgPath] = useState<string[]>([]);
  const [detailSetImgPath, setDetailSetImgPath] = useState<string[]>([]);
  const [detailImg, setDetailImg] = useState<any[]>([]);
  const [categoryName, setCategoryName] = useState<string>("");

  //뒤로가기
  const [historyCount, setHistoryCount] = useState<number>(0);

  const [categoryList, setCategoryList] = useState<any[]>([]);

  useEffect(() => {
    window.scrollTo(0, 0);
    if (prdType === "single") {
      // getProductThumbnailImg();
      getSingleProduct();
    } else {
      // getProductSetThumbnailImg();
      getSetProductBaseInfo();
    }

    getAllCategories();

    // getAllForLandingPage();

    /**
     * 뒤로가기
     */
    const handlePopState = (event: any) => {
      console.log("브라우저의 뒤로가기 버튼이 클릭되었습니다!", event.state);
      setHistoryCount((prevCount) => prevCount + 1); // 클릭할 때마다 카운트 증가
    };

    // popstate 이벤트 리스너 등록
    window.addEventListener("popstate", handlePopState);

    // 초기 상태 추가 (예: 페이지가 로드될 때)
    window.history.pushState({ page: 1 }, "title 1", window.location.href);

    return () => {
      // 컴포넌트 언마운트 시 리스너 제거
      window.removeEventListener("popstate", handlePopState);
    };
  }, []);

  /**
   * 뒤로 가기
   */
  useEffect(() => {
    // 두 번째 클릭 시 페이지 이동
    if (historyCount === 1) {
      if (prdType === "set") {
        // 예: '/previous-page'로 이동
        window.location.href = `/product?setCategoryId=${prdId}`; // 원하는 URL로 변경
      } else if (prdType === "single") {
        window.location.href = `/product?categoryId=${categoryId}`; // 원하는 URL로 변경
      }
    } else {
      window.history.pushState({ page: 1 }, "title 1", window.location.href);
    }
  }, [historyCount]);

  /**imagePath 있을 때, 이미지 api 호출 */
  useEffect(() => {
    if (detailImgPath.length > 0) {
      getSingleProductImgList();
    } else if (detailSetImgPath.length > 0) {
      getSetProductImgList();
    }
  }, [detailImgPath, detailSetImgPath]);

  const categoryParams = {
    order_by: "category_id",
    order_type: "asc",
    set_order_by: "set_category_id",
    set_order_type: "asc",
  };

  const getAllCategories = () => {
    GetAllCategories(current_cookies, categoryParams).then((response) => {
      const categoryData = response.data.data.categories_info.categories;

      const prdList = response.data.data.categories_info.categories;
      const setPrdList = response.data.data.set_categories_info.set_categories;

      const categoryDataList = [...prdList, ...setPrdList];

      setCategoryList([...prdList, ...setPrdList]);

      if (prdType === "single") {
        const result = categoryDataList.filter((item: any) => {
          return item.category_id == categoryId;
        });

        setCategoryName(result[0].category_name);
      } else {
        const result = categoryDataList.filter((item: any) => {
          return item.set_category_id == prdId;
        });

        console.log("result : ", result);

        setCategoryName(result[0].category_name);
      }
    });
  };

  /**단일 상품 썸네일 */
  const getProductThumbnailImg = () => {
    GetProductThumbnailImg(current_cookies, prdId).then((response) => {
      const ImageBlob = new Blob([response.data], { type: response.data.type });
      const ImgUrl = URL.createObjectURL(ImageBlob);

      setThumbnail(ImgUrl);
    });
  };

  /**세트 상품 썸네일 */
  const getProductSetThumbnailImg = () => {
    // GetSetProductThumbnail(current_cookies, Number(prdId), prdName).then(
    //   (response) => {
    //     const ImageBlob = new Blob([response.data], {
    //       type: response.data.type,
    //     });
    //     const ImgUrl = URL.createObjectURL(ImageBlob);

    //     setThumbnail(ImgUrl);
    //   }
    // );
    GetProductSetThumbnailImg(current_cookies, prdId, prdName).then(
      (response) => {
        const ImageBlob = new Blob([response.data], {
          type: response.data.type,
        });
        const ImgUrl = URL.createObjectURL(ImageBlob);

        setThumbnail(ImgUrl);
      }
    );
  };

  /**단일 상품 정보 */
  const getSingleProduct = () => {
    GetSingleProduct(current_cookies, prdId).then((response) => {
      if (response.data.status_info.status_code === 200) {
        setProductInfo(response.data.data);
        getSingleProductImgPath();
        // setThumbnail
      }
    });
  };

  /**세트 상품 정보 */
  const getSetProductBaseInfo = () => {
    GetSetProductBaseInfo(current_cookies, Number(prdId), prdName).then(
      (response) => {
        if (response.data.status_info.status_code === 200) {
          // console.log(
          //   "response ?? : ",
          //   response.data.data.set_products_info.set_products
          // );

          setProductInfo(response.data.data);
          getSetProductDetailImageInfo();
        }
      }
    );
  };

  /**단일 상품 디테일 이미지 경로 가져오기 api*/
  const getSingleProductImgPath = () => {
    GetSingleProductImgPath(current_cookies, prdId).then((response) => {
      const parsedData = response.data.data.map((item: any) => {
        return `data:image/jpeg;base64,${Object.values(item)[0]}`;
      });

      if (response.data.status_info.status_code === 200) {
        setDetailImg(parsedData);
      }
    });
  };

  /**단일 상품 디테일 리스트 가져오기 api*/
  const getSingleProductImgList = async () => {
    const list: any[] = [];
    const imgUrlList: any[] = [];

    try {
      const promises = detailImgPath.map(async (id, index) => {
        const response = await GetSingleProductImgList(
          current_cookies,
          prdId,
          Number(id)
        );
        const imageBlob = new Blob([response.data], {
          type: response.data.type,
        });
        const imageUrl = URL.createObjectURL(imageBlob);

        // Blob을 File 객체로 변환
        const file = new File([imageBlob], `image_${id}.jpg`, {
          type: response.data.type,
        });

        // 리스트에 파일 추가
        // list.push(file);
        list[index] = file;

        // imgUrlList.push(imageUrl);
        imgUrlList[index] = imageUrl;
      });

      // 모든 비동기 작업이 완료될 때까지 기다림
      await Promise.all(promises);

      // 화면용 디테일 이미지
      setDetailImg(imgUrlList);

      // // 필요한 경우 데이터 전송용 이미지 설정
      // setDetailImgData(list);
    } catch (error) {
      console.error("An error occurred while fetching images:", error);
    }
  };

  /**세트 상품 디테일 이미지 경로 가져오기 api*/
  const getSetProductDetailImageInfo = () => {
    GetSetProductDetailImageInfo(current_cookies, Number(prdId), prdName).then(
      (response) => {
        if (response.data.status_info.status_code === 200) {
          const parsedData = response.data.data.map((item: any) => {
            return `data:image/jpeg;base64,${Object.values(item)[0]}`;
          });
          setDetailImg(parsedData);
        }
      }
    );
  };

  /**세트 상품 디테일 리스트 가져오기 api*/
  const getSetProductImgList = async () => {
    const list: any[] = [];
    const imgUrlList: any[] = [];

    try {
      const promises = detailSetImgPath.map(async (id, index) => {
        console.log("");

        const response = await GetSetProductImgList(
          current_cookies,
          Number(prdId),
          prdName,
          Number(id)
        );
        const imageBlob = new Blob([response.data], {
          type: response.data.type,
        });
        const imageUrl = URL.createObjectURL(imageBlob);

        // Blob을 File 객체로 변환
        const file = new File([imageBlob], `image_${id}.jpg`, {
          type: response.data.type,
        });

        // 리스트에 파일 추가
        // list.push(file);
        list[index] = file;

        // imgUrlList.push(imageUrl);
        imgUrlList[index] = imageUrl;
      });

      // 모든 비동기 작업이 완료될 때까지 기다림
      await Promise.all(promises);

      // 화면용 디테일 이미지
      setDetailImg(imgUrlList);

      // 필요한 경우 데이터 전송용 이미지 설정
      // setDetailImgData(list);
    } catch (error) {
      console.error("An error occurred while fetching images:", error);
    }
  };

  const getAllForLandingPage = () => {
    GetAllForLandingPage(current_cookies).then((response) => {
      if (response.data.status_info.status_code) {
        const prdList = response.data.data.single_category_prd_list;
        const setPrdList = response.data.data.set_category_set_name_list;

        setCategoryList([...prdList, ...setPrdList]);
        // setCategoryName([...prdList, ...setPrdList]);
      }
    });
  };

  return (
    <PageWrap>
      <Header isWhiteLogo={false} categoryName={categoryList} />
      <ContentWrap>
        <Title>
          <p>
            <Link to={`/product?categoryId=${categoryList[0]?.category_id}`}>
              상품소개
            </Link>{" "}
            &gt;{" "}
            <Link
              to={
                prdType === "set"
                  ? `/product?setCategoryId=${prdId}&type=set`
                  : `/product?categoryId=${categoryId}`
              }
            >
              {productInfo.set_category_info?.set_category_name || categoryName}{" "}
            </Link>
            &gt;{" "}
            {productInfo.prd_name
              ? productInfo.prd_name
              : productInfo.set_products_info?.set_products[0].set_name}
          </p>
        </Title>
        <ThumbNail>
          <ThumbNailImg>
            <img
              src={`data:image/jpeg;base64,${
                productInfo?.set_category_info?.set_prd_thumbnail_img ||
                productInfo.prd_thumbnail_img
              }`}
            />
          </ThumbNailImg>
          <ThumbNailDetail>
            <h3>상품 정보</h3>
            <div>
              <p>상품이름</p>
              <p>
                <b>
                  {" "}
                  {productInfo.prd_name
                    ? productInfo.prd_name
                    : productInfo.set_products_info?.set_products[0].set_name}
                </b>
              </p>
            </div>
            {productInfo.prd_standard && productInfo.prd_quantity ? (
              <>
                <div>
                  <p>규격</p>
                  <p>{productInfo.prd_standard}</p>
                </div>

                <div>
                  <p>매수</p>
                  <p>{productInfo.prd_quantity}</p>
                </div>
              </>
            ) : (
              // productInfo.set_products_info?.set_products[0].set_prd_standard
              //productInfo.set_products_info?.set_products[0].set_prd_quantity
              <></>
            )}
            {productInfo.set_products_info?.set_products.length > 0 && (
              <div>
                <p>구성품</p>
                <p>
                  {productInfo.set_products_info?.set_products.map(
                    (item: any, index: number) => {
                      return (
                        <div key={index} className="prd-list">
                          {item.set_prd_name} | {item.set_prd_quantity}매 |{" "}
                          {item.set_prd_standard}
                        </div>
                      );
                    }
                  )}
                </p>
              </div>
            )}
            <div>
              <p>박스 입수</p>
              <p>
                {productInfo.prd_box_count
                  ? productInfo.prd_box_count
                  : productInfo.set_products_info?.set_products[0]
                      .set_box_count}
              </p>
            </div>
          </ThumbNailDetail>
        </ThumbNail>
        <ContentDetail>
          <h4>상품소개</h4>

          <div>
            {detailImg.map((item) => {
              return <img src={item ? item.toString() : ""} />;
            })}
          </div>
        </ContentDetail>
      </ContentWrap>
    </PageWrap>
  );
}
