import { BrowserRouter, Route, Routes } from "react-router-dom";
import { ProductionProcess } from "../pages/client/ProductionProcess";
import {
  CeoIntroduce,
  CompanyHistory,
  Landing,
  Product,
  ProductDetail,
  AdminLogin,
  AdminJoin,
  FindPassword,
  SetNewPassword,
  ManageProduct,
  ManageCategory,
  SelectUploadProduct,
  SingleProduct,
  MultiProduct,
  ManageSetProduct,
  ManageSetCategory,
  CompleteResetPw,
  ManagementPolicy,
} from "./index";

export function Router() {
  return (
    <BrowserRouter>
      <Routes>
        {/* 클라이언트 */}
        <Route path="/" element={<Landing />} />
        <Route path="/product" element={<Product />} />
        <Route path="/company-history" element={<CompanyHistory />} />
        <Route path="/product-detail" element={<ProductDetail />} />
        <Route path="/ceo" element={<CeoIntroduce />} />
        <Route path="/management_policy" element={<ManagementPolicy />} />
        <Route path="/production_process" element={<ProductionProcess />} />
        {/* 관리자 */}
        <Route path="/admin-login" element={<AdminLogin />} />
        <Route path="/admin-join" element={<AdminJoin />} />
        <Route path="/reset-password" element={<FindPassword />} />
        <Route path="/set-new-password" element={<SetNewPassword />} />
        <Route path="/complete-reset-password" element={<CompleteResetPw />} />
        <Route path="/manage-product" element={<ManageProduct />} />
        <Route path="/manage-set-product" element={<ManageSetProduct />} />
        <Route path="/manage-category" element={<ManageCategory />} />
        <Route path="/manage-set-category" element={<ManageSetCategory />} />
        <Route
          path="/select-upload-product"
          element={<SelectUploadProduct />}
        />
        <Route path="/single-product" element={<SingleProduct />} />
        <Route path="/multiple-product" element={<MultiProduct />} />
      </Routes>
    </BrowserRouter>
  );
}
