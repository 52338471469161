import { TextField } from "@mui/material";
import { useController, useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import { styled } from "styled-components";
import logoImg from "../../assets/img/cleaneco_logo-color.png";
import Cookies from "universal-cookie";
import { ResetPassword } from "../../api/user/ResetPassword";
import { useContext } from "react";
import { UserContext } from "../../contexts/UserIdProvider";
import { toast } from "react-toastify";

const PageWrap = styled.div`
  width: 500px;
  margin: 0 auto;
`;
const Logo = styled.div`
  width: 100%;
  text-align: center;
  margin-top: 40px;
  img {
    width: 100px;
  }
`;
const LoginSection = styled.div`
  width: 260px;
  margin: 80px auto 0;
  h4 {
    text-align: center;
    padding-bottom: 40px;
    font-weight: 400;
  }
`;

const Form = styled.form`
  width: 100%;
  height: fit-content;
  /* display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center; */

  div {
    width: 100%;

    & + div {
      margin-top: 20px;
    }
  }

  button {
    margin-top: 30px;
    width: 100%;
    height: 50px;
    background-color: #5bb331;
    border: none;
    color: #fff;
    cursor: pointer;
  }
`;

const LinkSection = styled.div`
  padding-top: 35px;
  text-align: center;

  a {
    color: #5bb331;
    text-decoration: underline;
    display: block;
    & + a {
      padding-top: 25px;
    }
  }
`;

export function SetNewPassword() {
  const { userId } = useContext(UserContext);

  const navigate = useNavigate();
  const cookies = new Cookies();
  const current_cookies = cookies.get("user_info");
  const { handleSubmit, control } = useForm();
  const {
    field: new_pw1,
    fieldState: { invalid: new_pw1_Field },
  } = useController({
    name: "new_pw1",
    control,
    defaultValue: "",
  });

  const {
    field: new_pw2,
    fieldState: { invalid: new_pw2_Field },
  } = useController({
    name: "new_pw2",
    control,
    defaultValue: "",
  });

  const onSubmit = (DATA: any) => {
    console.log("DATA : ", DATA);
    resetPassword(DATA);
  };

  const resetPassword = (DATA: any) => {
    ResetPassword(current_cookies, userId, DATA).then((response) => {
      if (response.data.status_info.status_code === 200) {
        navigate("/complete-reset-password");
        toast.success("비밀번호 재설정 성공");
      }
    });
  };

  return (
    <PageWrap>
      <Logo>
        <img src={logoImg} />
      </Logo>
      <LoginSection>
        <h4>새 비밀번호 설정</h4>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <TextField
            id="filled-basic"
            size="small"
            // fullWidth
            label="새 비밀번호 입력"
            variant="filled"
            {...new_pw1}
            error={new_pw1_Field}
            required
            type="password"
          />
          <TextField
            id="filled-basic"
            size="small"
            // fullWidth
            label="새 비밀번호 한번 더 입력"
            variant="filled"
            {...new_pw2}
            error={new_pw2_Field}
            required
            type="password"
          />
          <button>확인</button>
        </Form>
      </LoginSection>
    </PageWrap>
  );
}
