import axios from "axios";
const apiUrl = process.env.REACT_APP_API_URL;

export function GetSingleProductImgPath(token: string, prd_id: string) {
  return axios.get(`${apiUrl}/api/admin/product/detail-images/${prd_id}`, {
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: token,
    },
  });
}
