import { TextField } from "@mui/material";
import { useController, useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import { styled } from "styled-components";
import { CheckBeforeResetPw } from "../../api/user/CheckBeforeResetPw";
import logoImg from "../../assets/img/cleaneco_logo-color.png";
import Cookies from "universal-cookie";
import { useContext } from "react";
import { UserContext } from "../../contexts/UserIdProvider";
import { toast } from "react-toastify";

const PageWrap = styled.div`
  width: 500px;
  margin: 0 auto;
`;
const Logo = styled.div`
  width: 100%;
  text-align: center;
  margin-top: 40px;
  img {
    width: 100px;
  }
`;
const LoginSection = styled.div`
  width: 260px;
  margin: 80px auto 0;
  h4 {
    text-align: center;
    padding-bottom: 40px;
    font-weight: 400;
  }
`;

const Form = styled.form`
  width: 100%;
  height: fit-content;
  /* display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center; */

  div {
    width: 100%;

    & + div {
      margin-top: 20px;
    }
  }

  button {
    margin-top: 30px;
    width: 100%;
    height: 50px;
    background-color: #5bb331;
    border: none;
    color: #fff;
    cursor: pointer;
  }
`;

const LinkSection = styled.div`
  padding-top: 35px;
  text-align: center;

  a {
    color: #5bb331;
    text-decoration: underline;
    display: block;
    & + a {
      padding-top: 25px;
    }
  }
`;

export function FindPassword() {
  const navigate = useNavigate();

  const { setUserId } = useContext(UserContext);

  const cookies = new Cookies();
  const current_cookies = cookies.get("user_info");
  const { handleSubmit, control } = useForm();
  const {
    field: user_name,
    fieldState: { invalid: user_name_Field },
  } = useController({
    name: "user_name",
    control,
    defaultValue: "",
  });

  const {
    field: admin_code,
    fieldState: { invalid: admin_code_Field },
  } = useController({
    name: "admin_code",
    control,
    defaultValue: "",
  });

  const onSubmit = (DATA: any) => {
    console.log("DATA : ", DATA);

    checkBeforeResetPw(DATA);
  };

  const checkBeforeResetPw = (DATA: any) => {
    CheckBeforeResetPw(current_cookies, DATA).then((response) => {
      if (response.data.status_info.status_code === 200) {
        setUserId(DATA.user_name);
        navigate("/set-new-password");
        toast.success("기본정보가 확인되었습니다");
      }
    });
  };

  return (
    <PageWrap>
      <Logo>
        <img src={logoImg} />
      </Logo>
      <LoginSection>
        <h4>기본정보 확인</h4>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <TextField
            id="filled-basic"
            size="small"
            // fullWidth
            label="아이디"
            variant="filled"
            {...user_name}
            error={user_name_Field}
            required
          />
          <TextField
            id="filled-basic"
            size="small"
            // fullWidth
            label="관리자 코드"
            variant="filled"
            {...admin_code}
            error={admin_code_Field}
            required
            type="password"
          />
          <button>확인</button>
        </Form>
        <LinkSection>
          <Link to="/admin-login">로그인</Link>
          {/* <Link to="/admin_join">회원가입</Link> */}
        </LinkSection>
      </LoginSection>
    </PageWrap>
  );
}
