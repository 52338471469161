import axios from "axios";
const apiUrl = process.env.REACT_APP_API_URL;

export function GetSetProductBaseInfo(
  token: string,
  set_category_id: number,
  set_name: string
) {
  return axios.get(
    `${apiUrl}/api/admin/set-product/base-info/${set_category_id}/${set_name}`,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    }
  );
}
