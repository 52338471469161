import axios from "axios";
const apiUrl = process.env.REACT_APP_API_URL;

export function DeleteSingleProduct(token: string, prd_id: string) {
  return axios.delete(`${apiUrl}/api/admin/product/${prd_id}`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
  });
}
