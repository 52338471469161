import axios from "axios";
const apiUrl = process.env.REACT_APP_API_URL;
interface Props {
  page: number;
  rows: number;
  order_by: string;
  order_type: string;
  set_category_id?: any;
  set_prd_name?: any;
}

export function GetAllSetProduct(token: string, params: Props) {
  return axios.get(`${apiUrl}/api/admin/set-product`, {
    params: {
      page: params.page,
      rows: params.rows,
      order_by: params.order_by,
      order_type: params.order_type,
      set_category_id: params.set_category_id,
      set_prd_name: params.set_prd_name,
    },
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
  });
}
