import {
  Button,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { Controller, useController, useForm } from "react-hook-form";
import { styled } from "styled-components";
import { GetCategory } from "../../api/category/single/GetCategory";
import { AdminHeader } from "../../components/common/AdminHeader";
import Cookies from "universal-cookie";
import { PostSingleProduct } from "../../api/product/single/PostSingleProduct";
import { useNavigate } from "react-router-dom";
import queryString from "query-string";
import { GetSingleProduct } from "../../api/product/single/GetSingleProduct";
import { GetSingleProductThumbnail } from "../../api/product/single/GetSingleProductThumbnail";
import { GetSingleProductImgPath } from "../../api/product/single/GetSingleProductImgPath";
import { GetSingleProductImgList } from "../../api/product/single/GetSingleProductImgList";
import { PutSingleProduct } from "../../api/product/single/PutSingleProduct";
import { toast } from "react-toastify";
import { ProductDetailPreview } from "../../components/ProductDetailPreview";
import { CheckModal } from "../../components/modal/CheckModal";
import { RiInformation2Line } from "@remixicon/react";

const PageWrap = styled.div``;

const ContentWrap = styled.div`
  background-color: #efefef;
  width: 100%;
  /* height: calc(100vh - 80px); */
  height: fit-content;
  min-height: 450px;
  overflow: hidden;
  padding-bottom: 100px;
`;

const Title = styled.h4`
  width: 80%;
  margin: 50px auto 0;

  display: flex;
  align-items: center;

  p {
    position: relative;
    display: inline-block;
    margin-left: 10px;
    border-radius: 20px;
    text-align: center;
    cursor: pointer;

    ul {
      position: absolute;
      left: 40px;
      top: 0;
      background: rgba(255, 255, 255, 0.8);
      padding: 20px;
      box-sizing: border-box;
      border-radius: 10px;
      border: 1px solid #cbcbcb;
      line-height: 28px;
      z-index: 99;
      width: 500px;
      text-align: left;

      li {
        /* list-style: circle; */
        font-weight: 100;
        text-indent: 20px;

        &:first-child {
          list-style: none;
          font-weight: 400;
          padding-bottom: 20px;
        }
      }
    }
  }
`;

const Form = styled.form`
  width: 80%;
  margin: 20px auto 0;
`;

const FormInfoWrap = styled.div`
  width: 100%;
  border-top: 2px solid #444;
  background-color: #fff;
  padding: 20px;
  box-sizing: border-box;
`;

const ProductInfoSection = styled.div`
  padding: 10px 0 30px;
  border-bottom: 1px solid #cbcbcb;
  div {
    & + div {
      margin-left: 20px;
    }
  }
`;
const ThumbNailSection = styled.div`
  padding: 30px 0;
  border-bottom: 1px solid #cbcbcb;
  p {
    padding-bottom: 20px;
  }
`;
const DetailImgSection = styled.div`
  padding: 30px 0;
  p {
    padding-bottom: 20px;
  }
  div {
    display: flex;
    justify-content: flex-start;
    div {
      & + div {
        margin-left: 20px;
      }
    }
  }
`;

const FileUploadBtn = styled.div<{ imageFiles?: boolean }>`
  width: 100px;
  height: 100px;
  line-height: 100px;
  background-color: #e5e5e5;
  position: relative;
  cursor: pointer;
  /* overflow: hidden; */
  border: 1px solid #cbcbcb;

  &:before {
    content: "+";
    display: ${(props) => (props.imageFiles == false ? "block " : "none")};
    position: absolute;
    width: 100%;
    text-align: center;
    cursor: pointer;
    font-size: 50px;
    color: #a3a3a3;
    line-height: 110px;
  }
  input {
    display: block;
    width: 100%;
    height: 100%;
    /* position: absolute;
    z-index: -1; */
    opacity: 0;
    cursor: pointer;
  }
  img {
    width: 100%;
    height: 100%;
  }

  span {
    position: absolute;
    top: -10px;
    right: -10px;
    display: block;
    width: 30px;
    height: 30px;
    line-height: 30px;
    text-align: center;
    border-radius: 50px;
    /* background-color: rgba(0, 0, 0, 0.3); */
    background-color: #b0b0b0;
  }
`;

const ButtonSection = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  padding-top: 40px;

  button {
    width: 200px;
    height: 50px;
    line-height: 50px;
    border: 1px solid #444;
    color: #444;
    background-color: #fff;
    cursor: pointer;
    & + button {
      margin-left: 20px;
    }
  }
`;

export function SingleProduct() {
  const navigate = useNavigate();
  const cookies = new Cookies();
  const current_cookies = cookies.get("user_info");
  const queryParams = queryString.parse(window.location.search);

  const prdId =
    typeof queryParams.prd_id === "string" ? queryParams.prd_id : "";

  const [singlePrdList, setSinglePrdList] = useState<
    [{ category_id: number; category_name: string }]
  >([{ category_id: 0, category_name: "" }]);

  const [imageFiles, setImageFiles] = useState<File | any>(null);
  const [uploadImageFiles, setUploadImageFiles] = useState<File | null>(null);
  const [imageFiles01, setImageFiles01] = useState<File | null>(null);
  const [uploadImageFiles01, setUploadImageFiles01] = useState<File | null>(
    null
  );
  const [uploadImageFiles02, setUploadImageFiles02] = useState<File | null>(
    null
  );
  const [uploadImageFiles03, setUploadImageFiles03] = useState<File | null>(
    null
  );
  const [uploadImageFiles04, setUploadImageFiles04] = useState<File | null>(
    null
  );
  const [uploadImageFiles05, setUploadImageFiles05] = useState<File | null>(
    null
  );
  const [imageFiles02, setImageFiles02] = useState<string | null>(null);
  const [imageFiles03, setImageFiles03] = useState<string | null>(null);
  const [imageFiles04, setImageFiles04] = useState<string | null>(null);
  const [imageFiles05, setImageFiles05] = useState<string | null>(null);

  //데이터 있을 때
  const [data, setData] = useState<any>({});
  const [thumbnail, setThumbnail] = useState<any>();
  const [thumbnailData, setThumbnailData] = useState<any>();
  // const [detailImgPath, setDetailImgPath] = useState<string[]>([]);
  const [detailImg, setDetailImg] = useState<any[]>([]);
  const [detailImgData, setDetailImgData] = useState<any[] | any>([]);

  //미리보기 데이터
  const [previewData, setPreviewData] = useState<any>(null);

  const [isShowPreview, setIsShowPreview] = useState<boolean>(false);

  const [categoryInfo, setCategoryInfo] = useState<{
    category_id: number;
    category_name: string;
  }>({ category_id: 0, category_name: "" });

  const { control, handleSubmit, getValues, register } = useForm();

  const {
    field: single_product,
    fieldState: { invalid: single_product_Field },
  } = useController({
    name: "single_product",
    control,
    defaultValue: singlePrdList[0]?.category_name,
    // defaultValue: singlePrdList[0]?.category_name || "",
  });

  const {
    field: product_name,
    fieldState: { invalid: product_name_Field },
  } = useController({
    name: "product_name",
    control,
    defaultValue: "",
  });

  const {
    field: product_size,
    fieldState: { invalid: product_size_Field },
  } = useController({
    name: "product_size",
    control,
    defaultValue: "",
  });

  const {
    field: product_count,
    fieldState: { invalid: product_count_Field },
  } = useController({
    name: "product_count",
    control,
    defaultValue: "",
  });

  const {
    field: box_count,
    fieldState: { invalid: box_count_Field },
  } = useController({
    name: "box_count",
    control,
    defaultValue: "",
  });

  const countRef = useRef<any>(null);

  //뒤로가기
  const [historyCount, setHistoryCount] = useState<number>(0);
  const [isCheckModal, setIsCheckModal] = useState<boolean>(false);

  useEffect(() => {
    getCategory();
    if (prdId) {
      getSingleProduct();
    }

    const handlePopState = (event: any) => {
      console.log("브라우저의 뒤로가기 버튼이 클릭되었습니다!", event.state);
      setHistoryCount((prevCount) => prevCount + 1); // 클릭할 때마다 카운트 증가
      setIsCheckModal(true);
    };

    // popstate 이벤트 리스너 등록
    window.addEventListener("popstate", handlePopState);

    // 초기 상태 추가 (예: 페이지가 로드될 때)
    window.history.pushState({ page: 1 }, "title 1", window.location.href);

    return () => {
      // 컴포넌트 언마운트 시 리스너 제거
      window.removeEventListener("popstate", handlePopState);
    };
  }, []);

  useEffect(() => {
    // 두 번째 클릭 시 페이지 이동
    if (historyCount === 2) {
      // 예: '/previous-page'로 이동
      window.location.href = "/manage-product"; // 원하는 URL로 변경
    } else {
      window.history.pushState({ page: 1 }, "title 1", window.location.href);
    }
  }, [historyCount]);

  /**데이터 받아와서 필드에 적용 */
  useEffect(() => {
    const result = singlePrdList.filter(
      (item) => item.category_id === data.category_id
    );

    setCategoryInfo(result[0]);

    single_product.onChange({
      target: { value: result[0] && result[0]?.category_name },
    });
    product_name.onChange({ target: { value: data.prd_name } });
    product_size.onChange({ target: { value: data.prd_standard } });
    product_count.onChange({ target: { value: data.prd_quantity } });
    box_count.onChange({ target: { value: data.prd_box_count } });
  }, [singlePrdList, data]);

  /**imagePath 있을 때, 이미지 api 호출 */
  // useEffect(() => {
  //   if (detailImgPath.length > 0) {
  //     // getSingleProductImgList();
  //   }
  // }, [detailImgPath]);

  /**카테고리 리스트 조회 */
  const getCategory = () => {
    GetCategory(current_cookies)
      .then((response) => {
        setSinglePrdList(response.data.data.categories);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          alert("로그인 기한이 만료되었습니다.");
          navigate("/admin-login");
        }
      });
  };

  /**base64 => binary 로 변환 */
  const base64ToBinary = (base64Data: string) => {
    // Base64 문자열에서 'data:image/png;base64,' 부분을 제거
    const base64WithoutPrefix = base64Data.split(",")[1];

    // Base64 문자열을 바이너리 데이터로 변환
    const byteCharacters = atob(base64WithoutPrefix);
    const byteNumbers = new Uint8Array(byteCharacters.length);

    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }

    // Blob 객체 생성
    const blob = new Blob([byteNumbers], { type: "image/png" });

    // File 객체 생성 (name, lastModified, and type 지정)
    const file = new File([blob], "8156.png", {
      type: blob.type,
      lastModified: Date.now(),
    });

    console.log("file ---> ", file);

    return file;
  };
  // const base64ToBinary = (base64: string) => {
  //   // Base64 문자열을 디코딩
  //   const binaryString = atob(base64);

  //   // 바이너리 데이터를 Uint8Array로 변환
  //   const len = binaryString.length;
  //   const bytes = new Uint8Array(len);

  //   for (let i = 0; i < len; i++) {
  //     bytes[i] = binaryString.charCodeAt(i);
  //   }

  //   const blob = new Blob([bytes], { type: "image/png" });

  //   return blob;
  // };

  /**상품 정보 가져오기 api*/
  const getSingleProduct = () => {
    GetSingleProduct(current_cookies, prdId)
      .then((response) => {
        if (response.data.status_info.status_code === 200) {
          setData(response.data.data);

          const base64Data = response.data.data.prd_thumbnail_img;

          const imgSrc = `data:image/jpeg;base64,${base64Data}`;
          setImageFiles(imgSrc);

          const binaryData = base64ToBinary(imgSrc);
          setThumbnailData(binaryData); //post,put 전송용 이미지
        }
      })
      .catch((error) => {
        // if (error.response.status === 401) {
        //   // alert("로그인 기한이 만료되었습니다.");
        //   navigate("/admin-login");
        // }
      })
      .finally(() => {
        // getSingleProductThumbnail();
        getSingleProductImgPath();
      });
  };

  /**상품 썸네일 가져오기 api*/
  const getSingleProductThumbnail = () => {
    GetSingleProductThumbnail(current_cookies, prdId)
      .then((response) => {
        const imageBlob = new Blob([response.data], {
          type: response.data.type,
        });
        const imageUrl = URL.createObjectURL(imageBlob);

        // Blob을 File 객체로 변환
        const file = new File([imageBlob], "image.jpg", {
          type: response.data.type,
        });

        //화면용 썸네일
        setThumbnail(imageUrl);

        //데이터 전송용 썸네일
        setThumbnailData(file);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          // alert("로그인 기한이 만료되었습니다.");
          navigate("/admin-login");
        }
      });
  };

  /**상품 디테일 이미지 경로 가져오기 api*/
  const getSingleProductImgPath = () => {
    GetSingleProductImgPath(current_cookies, prdId)
      .then((response) => {
        if (response.data.status_info.status_code === 200) {
          const responData = response.data.data;

          const parsedData = responData.map((item: any) => {
            return `data:image/jpeg;base64,${Object.values(item)[0]}`;
          });

          setDetailImg(parsedData);

          const result = parsedData.map((item: string) => {
            return base64ToBinary(item);
          });

          setDetailImgData(result);

          // const base64ToBinaryData = parsedData.split(",")[1];
        }
      })
      .catch((error) => {
        // if (error.response.status === 401) {
        //   // alert("로그인 기한이 만료되었습니다.");
        //   navigate("/admin-login");
        // }
      });
  };

  /**상품 디테일 리스트 가져오기 api*/
  // const getSingleProductImgList = async () => {
  //   const list: any[] = new Array(5).fill(null);
  //   const imgUrlList: any[] = new Array(5).fill(null);

  //   // const list: any[] = [];
  //   // const imgUrlList: any[] = [];

  //   try {
  //     const promises = detailImgPath.map(async (id, index) => {
  //       const response = await GetSingleProductImgList(
  //         current_cookies,
  //         prdId,
  //         Number(id)
  //       );
  //       const imageBlob = new Blob([response.data], {
  //         type: response.data.type,
  //       });
  //       const imageUrl = URL.createObjectURL(imageBlob);

  //       // Blob을 File 객체로 변환
  //       const file = new File([imageBlob], `image_${id}.jpg`, {
  //         type: response.data.type,
  //       });

  //       // 리스트에 파일 추가
  //       // list.push(file);
  //       list[Number(id) - 1] = file;

  //       // imgUrlList.push(imageUrl);
  //       // imgUrlList[index] = { id: id, url: imageUrl };
  //       imgUrlList[Number(id) - 1] = imageUrl;
  //     });

  //     // 모든 비동기 작업이 완료될 때까지 기다림
  //     await Promise.all(promises);

  //     // console.log("list : ", list);

  //     // 화면용 디테일 이미지
  //     setDetailImg(imgUrlList);

  //     // 필요한 경우 데이터 전송용 이미지 설정
  //     setDetailImgData(list);
  //   } catch (error) {
  //     console.error("An error occurred while fetching images:", error);
  //   }
  // };

  // console.log("DetailImgData : ", detailImgData);

  /**상품 업로드 api */
  const postSingleProduct = (formData: FormData, params: any) => {
    PostSingleProduct(current_cookies, formData, params)
      .then((response) => {
        if (response.data.status_info.status_code === 201) {
          navigate("/manage-product");
          toast.success("성공적으로 등록되었습니다");
        } else {
          toast.error(response.data.status_info.message);
        }
      })
      .catch((error) => {
        if (error.response.status === 400) {
          toast.error(error.response.data.status_info.message);
        } else if (error.response.status === 422) {
          toast.error("양식을 완성해주세요");
        }
      });
  };

  /**상품 수정 api */
  const putSingleProduct = (formData: FormData, params: any) => {
    PutSingleProduct(current_cookies, prdId, formData, params)
      .then((response) => {
        if (response.data.status_info.status_code === 200) {
          navigate("/manage-product");
          toast.success("성공적으로 업데이트되었습니다");
        } else {
          toast.error(response.data.status_info.message);
        }

        console.log(
          "response.data.status_info.status_code : ",
          response.data.status_info
        );
      })
      .catch((error) => {
        console.log("error : ", error);
        if (error.response.status === 400) {
          toast.error(error.response.data.status_info.message);
        } else if (error.response.status === 422) {
          toast.error("양식을 완성해주세요");
        }
      });
  };

  /**이미지 화면에 올리기*/
  const handleFileChanges = (
    event: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    const files = event.target.files;

    if (files && files[0]) {
      const file = files[0];

      const fileName = file.name; //파일명

      if (fileName.length > 25) {
        toast.info("파일 이름은 25자를 초과할 수 없습니다.");
      } else {
        const reader = new FileReader();
        reader.onload = () => {
          if (index === 0) {
            setImageFiles(reader.result as any);
            setUploadImageFiles(file);
          } else if (index === 1) {
            setImageFiles01(reader.result as any);
            setUploadImageFiles01(file);
          } else if (index === 2) {
            setImageFiles02(reader.result as any);
            setUploadImageFiles02(file);
          } else if (index === 3) {
            setImageFiles03(reader.result as string);
            setUploadImageFiles03(file);
          } else if (index === 4) {
            setImageFiles04(reader.result as string);
            setUploadImageFiles04(file);
          } else {
            setImageFiles05(reader.result as string);
            setUploadImageFiles05(file);
          }
        };

        reader.readAsDataURL(file);
      }
    }
  };

  /**이미지 삭제 */
  const onHandleResetImage = (index: number) => {
    if (index === 0) {
      setImageFiles(null);
      setThumbnail(null);
      setThumbnailData(null);
    } else if (
      index === 1 ||
      index === 2 ||
      index === 3 ||
      index === 4 ||
      index === 5
    ) {
      // 이미지 파일 상태를 null로 설정
      if (index === 1) {
        setImageFiles01(null);
      } else if (index === 2) {
        setImageFiles02(null);
      } else if (index === 3) {
        setImageFiles03(null);
      } else if (index === 4) {
        setImageFiles04(null);
      } else if (index === 5) {
        setImageFiles05(null);
      }

      // detailImg에서 해당 인덱스를 null로 설정
      setDetailImg((prev: any) => {
        const newState = [...prev];
        newState[index - 1] = null; // index가 1이면 0번째 인덱스, 2이면 1번째 인덱스를 null로 설정
        return newState;
      });

      // detailImgData에서 해당 인덱스를 null로 설정
      setDetailImgData((prev: any) => {
        const newState = [...prev];
        newState[index - 1] = null; // index가 1이면 0번째 인덱스, 2이면 1번째 인덱스를 null로 설정
        return newState;
      });
    }
  };

  /**상품 업로드 */
  const onSubmit = (DATA: any) => {
    console.log("DATA : ", DATA);

    const filteredCategory = singlePrdList.filter(
      (item) => item.category_name === DATA.single_product
    );

    const formData = new FormData();

    if (thumbnailData !== null || uploadImageFiles !== null) {
      formData.append("prd_thumbnail_image", uploadImageFiles || thumbnailData);
    }

    if (detailImgData[0] || uploadImageFiles01) {
      formData.append(
        "prd_detail_image1",
        uploadImageFiles01 || detailImgData[0]
      );
    }

    if (detailImgData[1] || uploadImageFiles02) {
      formData.append(
        "prd_detail_image2",
        uploadImageFiles02 || detailImgData[1]
      );
    }

    if (detailImgData[2] || uploadImageFiles03) {
      formData.append(
        "prd_detail_image3",
        uploadImageFiles03 || detailImgData[2]
      );
    }

    if (detailImgData[3] || uploadImageFiles04) {
      formData.append(
        "prd_detail_image4",
        uploadImageFiles04 || detailImgData[3]
      );
    }

    if (detailImgData[4] || uploadImageFiles05) {
      formData.append(
        "prd_detail_image5",
        uploadImageFiles05 || detailImgData[4]
      );
    }

    const params = {
      category_id: filteredCategory[0].category_id,
      prd_name: DATA.product_name,
      prd_standard: DATA.product_size,
      prd_quantity: DATA.product_count,
      prd_box_count: DATA.box_count,
    };

    const isNotDigits = /[^0-9]/.test(DATA.product_count); // 0-9 외의 문자가 있는지 확인

    if (isNotDigits) {
      toast.error("매수는 숫자로 입력해주세요");
      if (countRef.current) {
        countRef.current.focus(); //매수 입력란에 포커싱
      }
    } else {
      if (data.category_id !== undefined) {
        putSingleProduct(formData, params);
      } else {
        postSingleProduct(formData, params);
      }
    }
  };

  /**미리보기 */
  const handlePreview = () => {
    const dataValue = getValues();

    setPreviewData(dataValue);

    if (
      dataValue.single_product == undefined ||
      dataValue.product_count == undefined ||
      dataValue.product_name == undefined ||
      dataValue.product_size == undefined
    ) {
      alert("상품 정보를 모두 입력해주세요");
    } else if (imageFiles === null && thumbnail === null) {
      alert("썸네일을 등록해주세요");
    } else {
      setIsShowPreview(true);
    }
  };

  const [showInfo, setShowInfo] = useState<boolean>(false);

  const handleShowInfo = () => {
    setShowInfo(true);
  };

  const handleHideInfo = () => {
    setShowInfo(false);
  };

  return (
    <>
      {!isShowPreview ? (
        <PageWrap>
          <AdminHeader />
          <ContentWrap>
            <Title>
              단일 상품 업로드
              <p onMouseOver={handleShowInfo} onMouseLeave={handleHideInfo}>
                <RiInformation2Line size={24} color="#229CF0" />{" "}
                {showInfo && (
                  <ul>
                    <li>상품 업로드 가이드</li>
                    <li>
                      1) 썸네일과 첫 번째 상세 이미지 1개는 필수로
                      등록해야합니다.
                    </li>
                    <li>2) 이미지 파일 크기는 20MB 이하로 제한합니다.</li>
                    <li>
                      3) 이미지 확장자는 jpg, jpeg, png, gif로 제한합니다.
                    </li>
                    <li>4) 이미지 파일명은 30자 이하로 제한합니다.</li>
                    <li>5) 이미지 파일명에 특수문자를 제거해주세요.</li>
                    <li>
                      6) 썸네일 이미지 파일명에 thumbnail이 포함되지 않도록
                      해주세요.
                    </li>
                    <li>
                      7) 상세 이미지 파일명에 detail이 포함되지 않도록 해주세요.
                    </li>
                  </ul>
                )}
              </p>
            </Title>
            <Form onSubmit={handleSubmit(onSubmit)}>
              <FormInfoWrap>
                <ProductInfoSection>
                  <FormControl
                    variant="filled"
                    size="small"
                    error={control.getFieldState("single_product").invalid}
                    required
                    style={{ width: "200px" }}
                  >
                    <InputLabel id="select-label">
                      단일 상품 카테고리
                    </InputLabel>
                    <Controller
                      name="single_product"
                      control={control}
                      rules={{ required: "옵션을 선택해주세요" }}
                      render={({ field, fieldState: { invalid, error } }) => (
                        <>
                          <Select
                            {...single_product}
                            labelId="select-label"
                            label="옵션"
                            defaultValue={singlePrdList[0]?.category_name || ""}
                          >
                            {singlePrdList.length > 0 ? (
                              singlePrdList.map((item, index) => {
                                return (
                                  <MenuItem
                                    key={item.category_id}
                                    value={item.category_name}
                                  >
                                    {item.category_name}
                                  </MenuItem>
                                );
                              })
                            ) : (
                              <MenuItem>
                                <p
                                  style={{
                                    fontSize: "13px",
                                    fontWeight: "300",
                                    color: "#8e8e8e",
                                  }}
                                >
                                  단일 카테고리 등록을 해주세요
                                </p>
                              </MenuItem>
                            )}
                          </Select>
                          {error && (
                            <FormHelperText>{error.message}</FormHelperText>
                          )}
                        </>
                      )}
                    />
                  </FormControl>
                  <TextField
                    id="filled-basic"
                    size="small"
                    // fullWidth
                    label="품명"
                    variant="filled"
                    {...product_name}
                    error={product_name_Field}
                    required
                    defaultValue={data.prd_name}
                  />
                  <TextField
                    id="filled-basic"
                    size="small"
                    // fullWidth
                    label="규격"
                    variant="filled"
                    {...product_size}
                    error={product_size_Field}
                    required
                  />
                  <TextField
                    id="filled-basic"
                    size="small"
                    // fullWidth
                    label="매수"
                    variant="filled"
                    {...product_count}
                    error={product_count_Field}
                    required
                    inputRef={(e: any) => {
                      // register(e); // react-hook-form에 등록
                      countRef.current = e; // ref 설정
                    }}
                  />
                  <TextField
                    id="filled-basic"
                    size="small"
                    // fullWidth
                    label="박스 입수"
                    variant="filled"
                    {...box_count}
                    error={box_count_Field}
                    required
                    defaultValue={data.prd_box_count}
                  />
                </ProductInfoSection>
                <ThumbNailSection>
                  <p>썸네일</p>
                  <div>
                    <FileUploadBtn
                      imageFiles={
                        thumbnail == null && imageFiles == null ? false : true
                      }
                    >
                      {imageFiles ? (
                        <>
                          <span
                            onClick={() => {
                              onHandleResetImage(0);
                            }}
                          >
                            x
                          </span>
                          <img
                            src={
                              imageFiles.toString() ? imageFiles.toString() : ""
                            }
                          />
                        </>
                      ) : thumbnail ? (
                        <>
                          <span
                            onClick={() => {
                              onHandleResetImage(0);
                            }}
                          >
                            x
                          </span>
                          <img
                            src={
                              thumbnail.toString() ? thumbnail.toString() : ""
                            }
                          />
                        </>
                      ) : (
                        <input
                          type="file"
                          onChange={(event) => {
                            handleFileChanges(event, 0);
                          }}
                          accept="image/*, .png, .jpeg, .jpg"
                        />
                      )}
                    </FileUploadBtn>
                  </div>
                </ThumbNailSection>
                <DetailImgSection>
                  <p>상세 이미지</p>
                  <div>
                    <FileUploadBtn
                      imageFiles={
                        detailImg[0] == null && imageFiles01 == null
                          ? false
                          : true
                      }
                    >
                      {imageFiles01 || detailImg[0] ? (
                        <>
                          <span
                            onClick={() => {
                              onHandleResetImage(1);
                            }}
                          >
                            x
                          </span>
                          <img
                            src={
                              imageFiles01
                                ? imageFiles01.toString()
                                : detailImg[0]
                                ? detailImg[0].toString()
                                : ""
                            }
                          />
                        </>
                      ) : (
                        <input
                          type="file"
                          onChange={(event) => {
                            handleFileChanges(event, 1);
                          }}
                          accept="image/*, .png, .jpeg, .jpg"
                        />
                      )}
                    </FileUploadBtn>
                    <FileUploadBtn
                      imageFiles={
                        detailImg[1] == null && imageFiles02 == null
                          ? false
                          : true
                      }
                    >
                      {imageFiles02 || detailImg[1] ? (
                        <>
                          <span
                            onClick={() => {
                              onHandleResetImage(2);
                            }}
                          >
                            x
                          </span>
                          <img
                            src={
                              imageFiles02
                                ? imageFiles02.toString()
                                : detailImg[1]
                                ? detailImg[1].toString()
                                : ""
                            }
                          />
                        </>
                      ) : (
                        <input
                          type="file"
                          onChange={(event) => {
                            handleFileChanges(event, 2);
                          }}
                          accept="image/*, .png, .jpeg, .jpg"
                        />
                      )}
                    </FileUploadBtn>
                    <FileUploadBtn
                      imageFiles={
                        detailImg[2] == null && imageFiles03 == null
                          ? false
                          : true
                      }
                    >
                      {imageFiles03 || detailImg[2] ? (
                        <>
                          <span
                            onClick={() => {
                              onHandleResetImage(3);
                            }}
                          >
                            x
                          </span>
                          <img
                            src={
                              imageFiles03
                                ? imageFiles03.toString()
                                : detailImg[2]
                                ? detailImg[2].toString()
                                : ""
                            }
                          />
                        </>
                      ) : (
                        <input
                          type="file"
                          onChange={(event) => {
                            handleFileChanges(event, 3);
                          }}
                          accept="image/*, .png, .jpeg, .jpg"
                        />
                      )}
                    </FileUploadBtn>
                    <FileUploadBtn
                      imageFiles={
                        detailImg[3] == null && imageFiles04 == null
                          ? false
                          : true
                      }
                    >
                      {imageFiles04 || detailImg[3] ? (
                        <>
                          <span
                            onClick={() => {
                              onHandleResetImage(4);
                            }}
                          >
                            x
                          </span>
                          <img
                            src={
                              imageFiles04
                                ? imageFiles04.toString()
                                : detailImg[3]
                                ? detailImg[3].toString()
                                : ""
                            }
                          />
                        </>
                      ) : (
                        <input
                          type="file"
                          onChange={(event) => {
                            handleFileChanges(event, 4);
                          }}
                          accept="image/*, .png, .jpeg, .jpg"
                        />
                      )}
                    </FileUploadBtn>
                    <FileUploadBtn
                      imageFiles={
                        detailImg[4] == null && imageFiles05 == null
                          ? false
                          : true
                      }
                    >
                      {imageFiles05 || detailImg[4] ? (
                        <>
                          <span
                            onClick={() => {
                              onHandleResetImage(5);
                            }}
                          >
                            x
                          </span>
                          <img
                            src={
                              imageFiles05
                                ? imageFiles05.toString()
                                : detailImg[4]
                                ? detailImg[4].toString()
                                : ""
                            }
                          />
                        </>
                      ) : (
                        <input
                          type="file"
                          onChange={(event) => {
                            handleFileChanges(event, 5);
                          }}
                          accept="image/*, .png, .jpeg, .jpg"
                        />
                      )}
                    </FileUploadBtn>
                  </div>
                </DetailImgSection>
              </FormInfoWrap>
              <ButtonSection>
                <button type="button" onClick={handlePreview}>
                  미리보기
                </button>
                <button type="submit">등록</button>
              </ButtonSection>
            </Form>
          </ContentWrap>
        </PageWrap>
      ) : (
        <ProductDetailPreview
          data={previewData}
          thumbnail={imageFiles || thumbnail}
          setIsShowPreview={setIsShowPreview}
          detailImg={
            detailImg.length > 0
              ? detailImg
              : [
                  imageFiles01,
                  imageFiles02,
                  imageFiles03,
                  imageFiles04,
                  imageFiles05,
                ]
          }
          categoryInfo={categoryInfo}
          prdName={data.prd_name}
        />
      )}
      {isCheckModal && (
        <CheckModal
          setIsCheckModal={setIsCheckModal}
          setHistoryCount={setHistoryCount}
          historyCount={historyCount}
        />
      )}
    </>
  );
}
