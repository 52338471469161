import axios from "axios";
const apiUrl = process.env.REACT_APP_API_URL;

interface loginValue {
  user_name: string;
  user_pw: string;
  // isAdmin: string;
}

export function SignUp(loginValue: loginValue) {
  const params = new URLSearchParams();
  params.append("user_name", loginValue.user_name);
  params.append("user_pw", loginValue.user_pw);

  return axios.post(`user/sign-up`, params, {});
}
