import axios from "axios";
const apiUrl = process.env.REACT_APP_API_URL;

interface Props {
  order_by: string;
  order_type: string;
  set_order_by?: any;
  set_order_type?: any;
}

export function GetAllCategories(token: string, params: Props) {
  return axios.get(`${apiUrl}/api/client/all-categories`, {
    params: {
      order_by: params.order_by,
      order_type: params.order_type,
      set_order_by: params.set_order_by,
      set_order_type: params.set_order_type,
    },
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
  });
}
