import { TextField } from "@mui/material";
import { useController, useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import { styled } from "styled-components";
import logoImg from "../../assets/img/cleaneco_logo-color.png";
import { Login } from "../../api/user/Login";
import { useEffect } from "react";
import { GetCategory } from "../../api/category/single/GetCategory";
import { toast } from "react-toastify";

const PageWrap = styled.div`
  width: 500px;
  margin: 0 auto;
`;
const Logo = styled.div`
  width: 100%;
  text-align: center;
  margin-top: 40px;
  img {
    width: 100px;
  }
`;
const LoginSection = styled.div`
  width: 260px;
  margin: 80px auto 0;
  h4 {
    text-align: center;
    padding-bottom: 40px;
    font-weight: 400;
  }
`;

const Form = styled.form`
  width: 100%;
  height: fit-content;
  /* display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center; */

  div {
    width: 100%;

    & + div {
      margin-top: 20px;
    }
  }

  button {
    margin-top: 30px;
    width: 100%;
    height: 50px;
    background-color: #5bb331;
    border: none;
    color: #fff;
    cursor: pointer;
  }
`;

const LinkSection = styled.div`
  padding-top: 35px;
  text-align: center;

  a {
    color: #5bb331;
    text-decoration: underline;
    display: block;
    & + a {
      padding-top: 25px;
    }
  }
`;

export function AdminLogin() {
  const navigate = useNavigate();
  const { handleSubmit, control } = useForm();
  const {
    field: admin_id,
    fieldState: { invalid: admin_id_Field },
  } = useController({
    name: "admin_id",
    control,
    defaultValue: "",
  });

  const {
    field: admin_pw,
    fieldState: { invalid: admin_pw_Field },
  } = useController({
    name: "admin_pw",
    control,
    defaultValue: "",
  });

  const onSubmit = (DATA: any) => {
    postLogin({ user_name: DATA.admin_id, user_pw: DATA.admin_pw });
  };

  const postLogin = (DATA: any) => {
    Login(DATA)
      .then((response) => {
        if (response.data.status_info.status_code === 200) {
          toast.success("성공적으로 로그인되었습니다");

          //쿠키 저장
          document.cookie = `user_info = Bearer ${response.data.data.access_token}`;
          // document.cookie = `user_info = Bearer ${
          //   response.data.data.access_token
          // }, expires=${new Date()}, path=/`;

          navigate("/manage-product");
        } else {
          console.log("실패");
        }
      })
      .catch((err) => {
        if (err.response.status === 401) {
          toast.error("로그인 정보가 일치하지 않습니다");
        }
        console.log("error : ", err);
      });
  };

  return (
    <PageWrap>
      <Logo>
        <img src={logoImg} />
      </Logo>
      <LoginSection>
        <h4>관리자 로그인</h4>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <TextField
            id="filled-basic"
            size="small"
            // fullWidth
            label="아이디"
            variant="filled"
            {...admin_id}
            error={admin_id_Field}
            required
          />
          <TextField
            id="filled-basic"
            size="small"
            // fullWidth
            label="비밀번호"
            variant="filled"
            {...admin_pw}
            error={admin_pw_Field}
            required
            type="password"
          />
          <button>로그인</button>
        </Form>
        <LinkSection>
          <Link to="/reset-password">비밀번호 재설정</Link>
        </LinkSection>
      </LoginSection>
    </PageWrap>
  );
}
