import { Link, useNavigate } from "react-router-dom";
import { styled } from "styled-components";
import logoImg from "../../assets/img/cleaneco_logo-color.png";
import Cookies from "universal-cookie";

const PageWrap = styled.div`
  width: 500px;
  margin: 0 auto;
`;
const Logo = styled.div`
  width: 100%;
  text-align: center;
  margin-top: 40px;
  img {
    width: 100px;
  }
`;
const LoginSection = styled.div`
  width: 260px;
  margin: 80px auto 0;
  h4 {
    text-align: center;
    padding-bottom: 40px;
    font-weight: 400;
  }

  button {
    margin-top: 30px;
    width: 100%;
    height: 50px;
    background-color: #5bb331;
    border: none;
    color: #fff;
    cursor: pointer;
  }
`;

export function CompleteResetPw() {
  const navigate = useNavigate();
  const cookies = new Cookies();
  const current_cookies = cookies.get("user_info");

  return (
    <PageWrap>
      <Logo>
        <img src={logoImg} />
      </Logo>
      <LoginSection>
        <h4>비밀번호 설정이 완료되었습니다</h4>

        <button
          onClick={() => {
            navigate("/admin-login");
          }}
        >
          로그인 하러 가기
        </button>
      </LoginSection>
    </PageWrap>
  );
}
