import axios from "axios";
const apiUrl = process.env.REACT_APP_API_URL;

interface Props {
  new_pw1: string;
  new_pw2: string;
}

export function ResetPassword(token: string, user_name: string, data: Props) {
  return axios.post(
    `${apiUrl}/api/user/reset-password`,
    { user_name, ...data },
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    }
  );
}
