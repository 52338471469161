import axios from "axios";
const apiUrl = process.env.REACT_APP_API_URL;

interface Props {
  category_id: number;
  prd_name: string;
  prd_standard: string;
  prd_quantity: number;
  prd_box_count: number;
}

export function PostSingleProduct(
  token: string,
  formData: FormData,
  params: Props
) {
  //data: any
  return axios.post(`${apiUrl}/api/admin/product`, formData, {
    params: {
      category_id: params.category_id,
      prd_name: params.prd_name,
      prd_standard: params.prd_standard,
      prd_quantity: params.prd_quantity,
      prd_box_count: params.prd_box_count,
    },
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: token,
    },
  });
}
