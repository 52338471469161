import axios from "axios";
const apiUrl = process.env.REACT_APP_API_URL;

interface Props {
  category_id: number;
  update_category_name: string;
}

export function PutCategory(token: string, category_id: string, params: Props) {
  return axios.put(`${apiUrl}/api/admin/category/${category_id}`, null, {
    params: {
      category_id: params.category_id,
      update_category_name: params.update_category_name,
    },
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
  });
}
