import axios from "axios";
const apiUrl = process.env.REACT_APP_API_URL;

interface Props {
  user_name: string;
  admin_code: string;
}

export function CheckBeforeResetPw(token: string, data: Props) {
  return axios.post(`${apiUrl}/api/user/check-before-reset-password`, data, {
    headers: {
      "Content-Type": "application/json",
      //   Authorization: token,
    },
  });
}
