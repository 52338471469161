import { keyframes, styled } from "styled-components";
import { Header } from "../../components/common/Header";
import bgImg from "../../assets/img/cleaneco_background.jpg";
import Cookies from "universal-cookie";
import { useEffect, useState } from "react";
import { GetAllForLandingPage } from "../../api/client/GetAllForLandingPage";
import { GetAllCategories } from "../../api/client/GetAllCategories";
import image01 from "../../assets/img/production_process/image01.jpeg";
import image02 from "../../assets/img/production_process/image02.jpeg";
import image03 from "../../assets/img/production_process/image03.jpeg";
import image04 from "../../assets/img/production_process/image04.jpeg";

const PageWrap = styled.div``;
const BannerSection = styled.div`
  width: 100%;
  height: 150px;
  background: url(${bgImg}) center;
  background-repeat: no-repeat;
  background-size: cover;
  text-align: center;
  line-height: 150px;
  color: #fff;
  border-top: 2px solid #5bb331;

  h3 {
    font-size: 28px;
    font-weight: 300;
  }

  @media (max-width: 1000px) {
    h3 {
      font-size: 20px;
    }
  }
`;

const ContentWrap = styled.div`
  width: 760px;
  margin: 0 auto;
  padding-bottom: 100px;

  @media (max-width: 1000px) {
    width: auto;
    padding: 20px;
    box-sizing: border-box;
  }
`;

const ani = keyframes`
from{
  opacity: 0;
  margin-top:40px;
}to{
  opacity: 1;
  margin-top:0px;
}
`;

const TitleSection = styled.div`
  animation: ${ani} 1s;
  padding: 30px 0 15px;
  font-size: 22px;
  line-height: 41px;
  border-bottom: 3px solid #5bb331;
  margin-bottom: 20px;
  font-weight: 300;

  span {
    color: #5bb331;
  }
`;

const ProcessSection = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 50px 0;

  ul {
    width: 80%;
    display: flex;
    justify-content: space-between;
    position: relative;
    li {
      background-color: #5bb331;
      color: #fff;
      border-radius: 30px;
      padding: 10px 15px;
      box-sizing: border-box;
      display: flex;
      justify-content: center;
      font-size: 15px;
      p:first-child {
        padding-right: 10px;
      }
      p:last-child {
        white-space: unset;
        padding-top: 0px;
      }
    }

    &:before {
      content: "";
      position: absolute;
      left: 0;
      top: 49%;
      width: 100%;
      height: 2px;
      background-color: #5bb331;
      z-index: -1;
    }
  }

  @media (max-width: 1000px) {
    ul {
      width: 100%;

      li {
        flex-direction: column;
        justify-content: center;
        align-items: center;

        p:first-child {
          padding-right: 0px;
        }
        p:last-child {
          white-space: nowrap;
          padding-top: 6px;
        }
      }
    }
  }
`;

const ProcessImgDescSection = styled.div`
  ul {
    li {
      width: 50%;
      float: left;

      p {
        width: 100%;

        &:last-child {
          height: 300px;
          background-color: #cbcbcb;
        }
      }
    }
  }
`;

const TextSection = styled.div`
  animation: ${ani} 1s;
  font-weight: 300;
  h4 {
    font-size: 24px;
    line-height: 50px;
    padding: 0px 0 10px 0;
    color: #5bb331;
    position: relative;
    line-height: 30px;
    padding-left: 30px;
    &::before {
      content: "";
      display: block;
      width: 10px;
      height: 10px;
      background-color: #5bb331;
      border-radius: 1px;
      position: absolute;
      left: 0;
      /* top: 38px; */
      top: 9px;
    }
  }

  & > p {
    /* background-color: #cbcbcb; */
    height: auto;
    /* line-height: 36px;
    padding-top: 15px; */
    /* text-indent: 20px; */
    width: 100%;
    overflow: hidden;
    img {
      width: 100%;
    }
  }

  div {
    margin-top: 20px;
    background-color: #f2f2f2;
    border-top-right-radius: 25px;
    border-bottom-left-radius: 25px;
    padding: 30px;
    box-sizing: border-box;

    &.last_textbox {
      margin-top: 0px;
    }

    p {
      line-height: 30px;
      color: #797979;
    }
  }
`;

const DivideSection = styled.div`
  display: flex;
  justify-content: center;
  padding: 20px 0;

  p {
    height: 31px;
    width: 2px;
    background: #cbcbcb;
  }
`;

const BottomTextSection = styled.div`
  margin-top: 50px;
  font-weight: 300;
  height: 150px;
  margin-bottom: 50px;
  line-height: 43px;
  color: #444;
  font-size: 18px;
`;

export function ProductionProcess() {
  const cookies = new Cookies();
  const current_cookies = cookies.get("user_info");

  const [categoryName, setCategoryName] = useState<any[]>([]);

  useEffect(() => {
    // getAllForLandingPage();
    getAllCategories();
  }, []);

  const getAllForLandingPage = () => {
    GetAllForLandingPage(current_cookies).then((response) => {
      const prdList = response.data.data.single_category_prd_list;
      const setPrdList = response.data.data.set_category_set_name_list;

      setCategoryName([...prdList, ...setPrdList]);
    });
  };

  const params = {
    order_by: "category_id",
    order_type: "asc",
    set_order_by: "set_category_id",
    set_order_type: "asc",
  };

  const getAllCategories = () => {
    GetAllCategories(current_cookies, params).then((response) => {
      const prdList = response.data.data.categories_info.categories;
      const setPrdList = response.data.data.set_categories_info.set_categories;

      setCategoryName([...prdList, ...setPrdList]);
    });
  };

  return (
    <PageWrap>
      <Header isWhiteLogo={false} categoryName={categoryName} />
      <BannerSection>
        <h3>생산 공정</h3>
      </BannerSection>
      <ContentWrap>
        <TitleSection>
          <p>
            <span>크린에코</span>는 원단 생산에서 가공, 포장, 물류까지 <br />
            모든 과정을 엄격히 관리합니다
          </p>
        </TitleSection>
        <ProcessSection>
          <ul>
            <li>
              <p>01</p>
              <p>압출</p>
            </li>
            <li>
              <p>02</p>
              <p>가공</p>
            </li>
            <li>
              <p>03</p>
              <p>포장</p>
            </li>
            <li>
              <p>04</p>
              <p>보관 및 출고</p>
            </li>
          </ul>
        </ProcessSection>
        {/* <ProcessImgDescSection>
          <ul>
            <li>
              <p className="text">01 압출</p>
              <p className="img">
                <img src="" alt="압출 이미지" />
              </p>
            </li>
            <li>
              <p className="text">02 가공</p>
              <p className="img">
                <img src="" alt="가공 이미지" />
              </p>
            </li>
            <li>
              <p className="text">03 포장</p>
              <p className="img">
                <img src="" alt="포장 이미지" />
              </p>
            </li>
            <li>
              <p className="text">04 보관 및 출고</p>
              <p className="img">
                <img src="" alt="포장 이미지" />
              </p>
            </li>
          </ul>
        </ProcessImgDescSection> */}
        <TextSection>
          <h4>압출</h4>
          <p>
            <img src={image01} alt="압출 이미지" />
          </p>
        </TextSection>
        <DivideSection>
          <p></p>
        </DivideSection>
        <TextSection>
          <h4>가공</h4>
          <p>
            <img src={image02} alt="가공 이미지" />
          </p>
        </TextSection>
        <DivideSection>
          <p></p>
        </DivideSection>
        <TextSection>
          <h4>포장</h4>
          <p>
            <img src={image03} alt="포장 이미지" />
          </p>
        </TextSection>
        <DivideSection>
          <p></p>
        </DivideSection>
        <TextSection>
          <h4>보관 및 출고</h4>
          <p>
            <img src={image04} alt="보관 및 출고 이미지" />
          </p>
        </TextSection>
      </ContentWrap>
    </PageWrap>
  );
}
