import axios from "axios";
const apiUrl = process.env.REACT_APP_API_URL;

interface Props {
  category_id: number;
  prd_name: string;
  prd_standard: string;
  prd_quantity: number;
  prd_box_count: number;
}

export function PutSingleProduct(
  token: string,
  prd_id: string,
  formData: FormData,
  params: Props
) {
  //data: any
  return axios.put(`${apiUrl}/api/admin/product/${prd_id}`, formData, {
    params: {
      //   prd_id,
      update_category_id: params.category_id,
      update_prd_name: params.prd_name,
      update_prd_standard: params.prd_standard,
      update_prd_quantity: params.prd_quantity,
      update_prd_box_count: params.prd_box_count,
    },
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: token,
    },
  });
}
