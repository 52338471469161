import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { Controller, useController, useForm } from "react-hook-form";
import { styled } from "styled-components";
import { AdminHeader } from "../../components/common/AdminHeader";
import { Header } from "../../components/common/Header";
import { CommonTable } from "../../components/common/CommonTable";
import { Link, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { GetAllSingleProduct } from "../../api/product/single/GetAllSingleProduct";
import Cookies from "universal-cookie";
import { DeleteSingleProduct } from "../../api/product/single/DeleteSingleProduct";
import { GetCategory } from "../../api/category/single/GetCategory";
import { toast } from "react-toastify";
import { DeleteModal } from "../../components/modal/DeleteModal";

const PageWrap = styled.div`
  /* width: 80%;
  margin: 0 auto; */
`;
const ContentWrap = styled.div`
  width: 80%;
  margin: 0 auto;
`;

const Title = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 30px 0;

  button {
    border: 1px solid #aaa;
    background-color: #fff;
    width: 80px;
    height: 45px;
    cursor: pointer;
    transition: all 0.3s;

    &:hover {
      background-color: #dbdbdb;
    }
  }
`;

const TableSearchSection = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding-bottom: 40px;

  & > button {
    border: 1px solid #aaa;
    background-color: #fff;
    width: 80px;
    height: 45px;
    cursor: pointer;
    transition: all 0.3s;

    &:hover {
      background-color: #dbdbdb;
    }
  }
`;
const SearchForm = styled.form`
  /* width: 33%; */
  display: flex;
  justify-content: space-between;
  align-items: center;

  div {
    & + div {
      margin-left: 10px;
    }
  }

  button {
    margin-left: 10px;
    width: 80px;
    height: 45px;
    border: none;
    background-color: #cbcbcb;
    cursor: pointer;
    transition: all 0.3s;

    &:hover {
      background-color: #b6b6b6;
    }
  }
`;

interface GetProductParamProps {
  page: number;
  rows: number;
  order_by: string;
  order_type: string;
  category_id: any;
  prd_name: any;
}

export function ManageProduct() {
  const navigate = useNavigate();
  const cookies = new Cookies();
  const current_cookies = cookies.get("user_info");

  const maxRows = 10;

  //카테고리 리스트
  // const [categoryList, setCategoryList] = useState<
  //   { category_id: number; category_name: string }[]
  // >([]);
  const [pureCategoryList, setPureCategoryList] = useState<any[]>([]);
  const [categoryList, setCategoryList] = useState<any[]>([]);

  //테이블
  const [page, setPage] = useState<number>(1);
  const [data, setData] = useState<[] | any>([]);
  const [currentPageCount, setCurrentPageCount] = useState<number>(1);
  const [selectedRows, setSelectedRows] = useState<any[]>([]);
  const [selectedRow, setSelectedRow] = useState<any>({});

  const [prdId, setPrdId] = useState<number>(0);
  const [selected, setSelected] = useState<boolean>(false);

  //테이블 검색
  const [searchValue, setSearchValue] = useState<{
    search_text: string;
    single_product: string;
  }>({ search_text: "", single_product: "" });

  //삭제
  const [isDelete, setIsDelete] = useState<boolean>(false);
  const [deleteProduct, setDeleteProduct] = useState<boolean>(false);

  const { control, handleSubmit, reset } = useForm();

  const {
    field: single_product,
    fieldState: { invalid: single_product_Field },
  } = useController({
    name: "single_product",
    control,
    defaultValue: categoryList[0] || "",
  });

  const {
    field: search_text,
    fieldState: { invalid: search_text_Field },
  } = useController({
    name: "search_text",
    control,
    defaultValue: "",
  });

  /**카테고리 리스트에서 선택한 카테고리 필터링 */
  const filterList: any = pureCategoryList.filter(
    (item) => item.category_name === searchValue.single_product
  );

  const params = {
    page: page,
    rows: maxRows,
    order_by: "category_id",
    order_type: "asc",
    category_id: filterList[0]?.category_id || null,
    prd_name: searchValue.search_text || null,
  };

  const resetParams = {
    page: page,
    rows: maxRows,
    order_by: "category_id",
    order_type: "asc",
  };

  // useEffect(() => {
  //   console.log("current_cookies : ", current_cookies);
  //   if (current_cookies === "") {
  //     alert("로그인 기한이 만료되었습니다.");
  //     navigate("/admin-login");
  //   }
  // }, []);

  /**검색 시, api 호출 */
  useEffect(() => {
    getAllSingleProduct(current_cookies, params);
    getCategory();
  }, [searchValue, page]);

  /**테이블 선택 시, 아이디 값 추출 */
  useEffect(() => {
    setPrdId(selectedRow.prd_id);
  }, [selectedRow]);

  /**테이블 제목 클릭 시, 링크 이동 */
  useEffect(() => {
    if (selected && selectedRow.prd_id !== undefined) {
      // 새로운 URL을 설정합니다.
      navigate(`/single-product?prd_id=${selectedRow.prd_id}`);
    }
  }, [selectedRow.prd_id, selected]);

  useEffect(() => {
    if (deleteProduct === true) {
      deleteSingleProduct();
    }
  }, [deleteProduct]);

  /**카테고리 리스트 조회 */
  const getCategory = () => {
    GetCategory(current_cookies)
      .then((response) => {
        const responseData = response.data.data.categories;

        setPureCategoryList(responseData);

        const deepCopyData = JSON.parse(JSON.stringify(responseData));

        const result = deepCopyData.map((item: any) => {
          return item.category_name;
        });

        setCategoryList(result);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          alert("로그인 기한이 만료되었습니다.");
          navigate("/admin-login");
        }
      });
  };

  /**상품 리스트 조회 */
  const getAllSingleProduct = (
    current_cookies: string,
    params: GetProductParamProps
  ) => {
    GetAllSingleProduct(current_cookies, params)
      .then((response) => {
        if (response.data.status_info.status_code === 200) {
          setData(response.data.data.products_info.products);
          setCurrentPageCount(response.data.data.page_info.total_page);
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          // alert("로그인 기한이 만료되었습니다.");
          navigate("/admin-login");
        }
      });
  };

  /**상품 리스트 삭제 */
  const deleteSingleProduct = () => {
    selectedRows.forEach((row: any) => {
      DeleteSingleProduct(current_cookies, row.original.prd_id).then(
        (response) => {
          if (response.status === 204) {
            getAllSingleProduct(current_cookies, params);
            toast.success("성공적으로 삭제되었습니다");
            setDeleteProduct(false);
            setIsDelete(false);
          }
        }
      );
    });
  };

  const onSubmit = (DATA: any) => {
    console.log("DATA : ", DATA);
    setSearchValue(DATA);
  };

  const handleDelete = () => {
    if (selectedRows.length === 0) {
      alert("삭제할 상품을 선택해주세요");
    } else {
      setIsDelete(true);
    }
  };

  const handleReset = () => {
    reset({ single_product: "" }); //검색어 초기화

    GetAllSingleProduct(current_cookies, resetParams)
      .then((response) => {
        if (response.data.status_info.status_code === 200) {
          setData(response.data.data.products_info.products);
          setCurrentPageCount(response.data.data.page_info.total_page);
        }
      })
      .catch((error) => {
        console.log("error : ", error);
      });
  };

  const columnData = [
    { Header: "카테고리", accessor: "category_name" },
    {
      Header: "품명",
      accessor: "prd_name",
      Cell: ({ value }: any) => (
        <p
          onClick={() => {
            setSelected(true);
          }}
          style={{ cursor: "pointer", textDecoration: "underline" }}
        >
          {value}
        </p>
      ),
    },
    { Header: "규격", accessor: "prd_standard" },
    { Header: "매수", accessor: "prd_quantity" },
    { Header: "박스 입수", accessor: "prd_box_count" },
    { Header: "등록일자", accessor: "prd_created_at" },
  ];

  return (
    <>
      <PageWrap>
        <AdminHeader />
        <ContentWrap>
          <Title>
            <h4>단일 상품 관리</h4>
            <button
              onClick={() => {
                navigate("/select-upload-product");
              }}
            >
              상품 업로드
            </button>
          </Title>
          <TableSearchSection>
            <SearchForm onSubmit={handleSubmit(onSubmit)}>
              {/* <p>단일 상품 카테고리</p> */}
              <FormControl
                variant="filled"
                size="small"
                error={control.getFieldState("single_product").invalid}
                // required
                style={{ width: "200px" }}
              >
                <InputLabel id="select-label">단일 상품 카테고리</InputLabel>
                <Controller
                  name="single_product"
                  control={control}
                  // rules={{ required: "옵션을 선택해주세요" }}
                  render={({ field, fieldState: { invalid, error } }) => (
                    <>
                      <Select
                        {...single_product}
                        labelId="select-label"
                        label="옵션"
                      >
                        {categoryList.map((item: any, index) => {
                          return (
                            <MenuItem key={index} value={item}>
                              {item}
                            </MenuItem>
                          );
                        })}
                      </Select>
                      {error && (
                        <FormHelperText>{error.message}</FormHelperText>
                      )}
                    </>
                  )}
                />
              </FormControl>
              <TextField
                id="filled-basic"
                size="small"
                // fullWidth
                label="품명 검색"
                variant="filled"
                {...search_text}
                error={search_text_Field}
                // required
              />
              <button type="submit">검색</button>
              <button type="button" onClick={handleReset}>
                초기화
              </button>
            </SearchForm>
            <button onClick={handleDelete}>삭제</button>
          </TableSearchSection>
          <CommonTable
            useFor="singleProduct"
            setPage={setPage}
            page={page}
            data={data}
            totalPage={currentPageCount}
            columnData={columnData}
            setSelectedRow={setSelectedRow}
            setSelectedRows={setSelectedRows}
          />
        </ContentWrap>
      </PageWrap>
      {isDelete && (
        <DeleteModal
          setIsDelete={setIsDelete}
          setDeleteProduct={setDeleteProduct}
        />
      )}
    </>
  );
}
