import React from "react";
import { Router } from "./routes/Router";
import { GlobalStyle } from "./styles/GlobalStyle";
import { ToastContainer } from "react-toastify";
import { UserIdProvider } from "./contexts/UserIdProvider";

function App() {
  return (
    <>
      <UserIdProvider>
        <ToastContainer />
        <GlobalStyle />
        <Router />
      </UserIdProvider>
    </>
  );
}

export default App;
