import axios from "axios";
const apiUrl = process.env.REACT_APP_API_URL;

interface loginValue {
  user_name: string;
  user_pw: string;
  // isAdmin: string;
}

export function Login(loginValue: loginValue) {
  const params = new URLSearchParams();
  params.append("user_name", loginValue.user_name);
  params.append("user_pw", loginValue.user_pw);

  //   console.log("params : ", params.toString());

  return axios.post(`${apiUrl}/api/user/log-in`, loginValue, {
    headers: {
      "Content-Type": "application/json",
    },
  });
}
