import { Dispatch, SetStateAction } from "react";
import { styled } from "styled-components";

const Wrap = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.1);
`;

const ModalWrap = styled.div`
  position: absolute;
  z-index: 99;
  left: 50%;
  top: 50%;
  background-color: #fff;
  padding: 20px;
  box-sizing: border-box;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  border-radius: 7px;
  width: 200px;
  height: 180px;
  transform: translate(-100px, -100px);

  h3 {
    height: 100px;
    line-height: 100px;
    text-align: center;
  }

  div {
    display: flex;
    justify-content: space-around;

    button {
      width: 40%;
      background-color: #ededed;
      border: none;
      padding: 5px;
      border-radius: 3px;
      cursor: pointer;
      transition: all 0.3s;

      &:hover {
        background-color: #d3d3d3;
      }
    }
  }
`;

interface Props {
  setIsDelete: Dispatch<SetStateAction<boolean>>;
  setDeleteProduct: Dispatch<SetStateAction<boolean>>;
}

export function DeleteModal({ setIsDelete, setDeleteProduct }: Props) {
  return (
    <Wrap>
      <ModalWrap>
        <h3>삭제하시겠습니까?</h3>
        <div>
          <button
            onClick={() => {
              setDeleteProduct(true);
            }}
          >
            확인
          </button>
          <button
            onClick={() => {
              setIsDelete(false);
              setDeleteProduct(false);
            }}
          >
            취소
          </button>
        </div>
      </ModalWrap>
    </Wrap>
  );
}
