import { createGlobalStyle } from "styled-components";
import NanumBarunGothic from "../assets/fonts/NanumBarunGothic.ttf";
import NanumBarunGothicLight from "../assets/fonts/NanumBarunGothicLight.ttf";
import NanumBarunGothicBold from "../assets/fonts/NanumBarunGothicBold.ttf";

export const GlobalStyle = createGlobalStyle`

@font-face {
    font-family: "NanumBarunGothic";
    src: url(${NanumBarunGothicLight})  format("truetype");
    font-weight:100;
}

@font-face {
    font-family: "NanumBarunGothic";
    src: url(${NanumBarunGothic})  format("truetype");
    font-weight:300;
}
@font-face {
    font-family: "NanumBarunGothic";
    src: url(${NanumBarunGothicBold})  format("truetype");
    font-weight:400;
}

    *{
        padding:0;
        margin:0;
        font-family: "NanumBarunGothic";
    }

    li{
        list-style:none;
    }

    a{
        color:#000;
        text-decoration: none;
    }
`;
