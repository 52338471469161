import axios from "axios";
const apiUrl = process.env.REACT_APP_API_URL;

export function GetAllForLandingPage(token: string) {
  return axios.get(`${apiUrl}/api/client/all-for-landing-page`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
  });
}
