import axios from "axios";
const apiUrl = process.env.REACT_APP_API_URL;

interface Props {
  page: number;
  rows: number;
  order_by: string;
  order_type: string;
}

export function GetSetCategory(token: string, params: Props) {
  return axios.get(`${apiUrl}/api/admin/set-category`, {
    params,
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
  });
}
