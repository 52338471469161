import axios from "axios";
const apiUrl = process.env.REACT_APP_API_URL;

interface Props {
  set_category_id: number;
  set_name: string;
  update_set_category_id: string;
  update_set_name: string;
  update_set_box_count: string;
}

export function PutSetProductBaseInfo(
  token: string,
  data: any,
  params: Props,
  originalCategoryId: number,
  originalSetName: string
) {
  //data: any

  return axios.put(
    `${apiUrl}/api/admin/set-product/base-info/${originalCategoryId}/${originalSetName}`,
    data,
    {
      params: {
        set_category_id: params.set_category_id,
        set_name: params.set_name,
        update_set_category_id: params.update_set_category_id,
        update_set_name: params.update_set_name,
        update_set_box_count: params.update_set_box_count,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    }
  );
}
