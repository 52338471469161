import axios from "axios";
const apiUrl = process.env.REACT_APP_API_URL;

export function PutSetProductImg(
  token: string,
  data: FormData,
  set_category_id: number,
  set_name: string
) {
  //data: any

  return axios.put(
    `${apiUrl}/api/admin/set-product/image/${set_category_id}/${set_name}`,
    data,
    {
      headers: {
        // "Content-Type": "multipart/form-data",
        //FormData를 사용할 때는 Content-Type을 수동으로 설정하지 않습니다.
        Authorization: token,
      },
    }
  );
}
